import { Container, Spinner, VStack, Text } from '@chakra-ui/react'
import {
    GRADIENT_DIR_ARR,
    TROOPS_GRADIENT_ARR,
    TROOPS_PALETTE_OBJ,
} from '../constants/TroopsPalette'

interface TroopsSpinnerProps {
    label?: string
}

export function TroopsSpinner({ label }: TroopsSpinnerProps) {
    return (
        <Container px={16} py={28}>
            <VStack spacing={[8, null, 4]}>
                <Spinner
                    size={['xl', null, 'lg']}
                    thickness="3px"
                    speed="1s"
                    emptyColor="gray.200"
                    color={TROOPS_PALETTE_OBJ.rose}
                    bgGradient={`linear(${GRADIENT_DIR_ARR[0]}, ${TROOPS_GRADIENT_ARR[0][0]}, ${TROOPS_GRADIENT_ARR[0][1]} )`}
                />
                <Text fontSize={['xl', null, 'lg']}>{label}</Text>
            </VStack>
        </Container>
    )
}
