import { useSIWE } from 'connectkit'
import { useRouter } from 'next/router'
import { Link } from '@chakra-ui/next-js'
import { useAccount } from 'wagmi'

import {
    Alert,
    AlertIcon,
    AlertDescription,
    Box,
    Button,
    Container,
    Flex,
    SimpleGrid,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react'

import { PageWithAppBar } from '../components/AppBar'
import TroopCard from '../components/troops/TroopCard'
import { SiweHookProps } from '../types'
import { GRADIENT_DIR_ARR, TROOPS_GRADIENT_ARR } from '../constants/TroopsPalette'
import useGetUserTroops from '../hooks/useGetUserTroops'
import { TroopsSpinner } from '../components/TroopsSpinner'

export default function Home() {
    const { reload } = useRouter()
    const toast = useToast()
    const { isSignedIn }: SiweHookProps = useSIWE()
    const { address: userAddress } = useAccount()

    const {
        isErrorTroopMemberships,
        isLoadingTroopMemberships,
        isSuccessTroopMemberships,
        troopsMemberships,
    } = useGetUserTroops(userAddress)

    const handleDeployTroopClick = () => {
        if (!isSignedIn && !toast.isActive('must-login'))
            toast({
                id: 'must-login',
                description: 'You must log in',
                status: 'warning',
                duration: 3000,
                isClosable: true,
                containerStyle: {
                    mb: '88px',
                },
            })
    }

    return (
        <PageWithAppBar>
            {!isSignedIn && (
                <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>Sign in to view your Troops</AlertDescription>
                </Alert>
            )}
            <Box p={4}>
                <Flex justifyContent="center" px={[0, null, 8, 16]} w="100%">
                    <Link
                        href={isSignedIn ? '/deploy-troop' : '#'}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        _active={{ textDecoration: 'none' }}
                        _hover={{ textDecoration: 'none' }}
                        _focus={{ textDecoration: 'none' }}
                        w={[null, null, '25%']}
                    >
                        <Button w="100%" variant="troop-gradient" onClick={handleDeployTroopClick}>
                            Deploy a Troop 🪖
                        </Button>
                    </Link>
                </Flex>

                {isSignedIn && isLoadingTroopMemberships && (
                    <TroopsSpinner label="Loading Troops..." />
                )}
                {isSignedIn && isSuccessTroopMemberships && (
                    <SimpleGrid
                        columns={[1, null, 2, 4, 4]}
                        spacing={[4, null, null, 2]}
                        py={4}
                        px={[0, null, 8, 16]}
                    >
                        {troopsMemberships?.map((troop, index) => (
                            <TroopCard
                                key={troop.id}
                                troop={troop}
                                bgGradient={`linear(${
                                    GRADIENT_DIR_ARR[index % (GRADIENT_DIR_ARR.length - 1)]
                                }, ${
                                    TROOPS_GRADIENT_ARR[index % (TROOPS_GRADIENT_ARR.length - 1)][0]
                                }, ${
                                    TROOPS_GRADIENT_ARR[index % (TROOPS_GRADIENT_ARR.length - 1)][1]
                                } )`}
                            />
                        ))}
                        {(!troopsMemberships || troopsMemberships.length === 0) && (
                            <Text textAlign="center" mt={16}>
                                No Troops found
                                <br />
                                Join one or deploy your own!
                            </Text>
                        )}
                    </SimpleGrid>
                )}
                {isSignedIn && isErrorTroopMemberships && (
                    <Container px={16} py={28}>
                        <VStack spacing={[8, null, 4]}>
                            <Text fontSize={['xl', null, 'lg']}>Something went wrong...</Text>
                            <Button px={6} variant="black-outline" onClick={reload}>
                                Reload
                            </Button>
                        </VStack>
                    </Container>
                )}
            </Box>
        </PageWithAppBar>
    )
}
