import { Card, Flex, Icon, Image, Text } from '@chakra-ui/react'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { TroopCardProps } from '../../types'
import { Link } from '@chakra-ui/next-js'

const TroopCard = ({ troop, bgGradient }: TroopCardProps) => {
    return (
        <Card key={troop.id} p={4} direction="row" overflow="hidden" gap={4}>
            <Flex alignItems="center" justifyContent="center" w="40%" p={[2, null, 4, 2]}>
                {/* 
                    // TODO - add metadata img
                    <Image
                    objectFit="cover"
                    src={troop.metadata.imageUri} // ?? Define metadata type
                    alt={`${troop.full_name}`}
                    rounded="md"
                    aspectRatio={1}
                    /> 
                */}
                <Flex
                    w="100%"
                    aspectRatio={1}
                    bgGradient={bgGradient}
                    rounded="lg"
                    p={2}
                    justifyContent="end"
                    alignItems="end"
                >
                    <Image
                        width="50%"
                        src="/troopie-assets/troopie-eyes-black.svg"
                        alt={`${troop.full_name}`}
                        aspectRatio={1}
                    />
                </Flex>
            </Flex>
            <Flex w="60%" flexDirection="column" justifyContent="center" position="relative">
                <Flex alignItems="center" w="100%">
                    <Link
                        href={`/t/${troop.id}`}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap={2}
                        w="100%"
                    >
                        <Text noOfLines={2} fontSize={['lg', null, 'md', 'sm']} w="80%">
                            {troop.full_name}
                        </Text>{' '}
                        <ArrowForwardIcon w="20%" />
                    </Link>
                </Flex>
                <Flex
                    justifyContent="end"
                    alignItems="center"
                    gap={6}
                    position="absolute"
                    bottom={[2, null, 0, -2]}
                    right={2}
                    w="100%"
                >
                    <Flex alignItems="center">
                        <Icon
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="gray.500"
                            w={[6, null, 4, null]}
                            h={[6, null, 4, null]}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                            />
                        </Icon>
                    </Flex>
                    <Flex>
                        <Icon
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="gray.500"
                            w={[6, null, 4, null]}
                            h={[6, null, 4, null]}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                            />
                        </Icon>
                    </Flex>
                    <Flex>
                        <Icon
                            fill="none"
                            viewBox="0 0 15 15"
                            strokeWidth={0.5}
                            stroke="gray.300"
                            w={[6, null, 4, null]}
                            h={[6, null, 4, null]}
                        >
                            <path
                                fill="currentColor"
                                fillRule="evenodd"
                                d="M4.5 2a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM3 4.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM10.5 2a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM9 4.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm-7 6a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zM4.5 9a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6-1a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM9 10.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
                                clipRule="evenodd"
                            />
                        </Icon>
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    )
}

export default TroopCard
